import React, { Component } from 'react';

class ContentGrid extends Component {
  render() {
    return (
      <div className="content-grid" style={{ gridTemplateColumns: this.props.desktopColumns, gridColumnGap: this.props.columnSpacing, gridRowGap: this.props.rowSpacing }}>
        {this.props.elements.map((element, index) => <div>{element}</div>)}
      </div>
    );
  }
}

export default ContentGrid;

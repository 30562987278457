import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PaddedSection from '../components/PaddedSection'
import ContentGrid from '../components/ContentGrid'
import ImgNextGen from "../components/ImgNextGen"
import config from "../components/config"
//import config from "../components/config"
import '../scss/home.scss'
// const config = {
//   img: 'https://pixopixa.cyou/images/',
//   backend: 'https://pixopixa.cyou'
// }

class IndexPage extends Component {
  state = {
    show: false
  };

 render(){
     return (
        <Layout>
          <SEO title="PixoPixa" />
          <PaddedSection width="wide">
            <h1 className="filled-text-red" style={{textAlign: "center", fontSize: "2.5rem"}}>OUR FEATURED GAMES</h1>
            <ContentGrid
              desktopColumns="48% 48%"
              mobileColumns="100%"
              columnSpacing="3%"
              rowSpacing="30px"
              elements={[
                <Link style={{textAlign: "center"}} to="/game-preview/holly-halls-hopper" >
                  <div style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <br/>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>HOLLY HALLS HOPPER</h2>
                    <ImgNextGen width="80px" height="80px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.webp"}/><ImgNextGen width="80px" height="80px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-villianSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-villianSprite.webp"}/><ImgNextGen width="50px" height="50px" fallback={config.img + "ghost1.png"} srcWebp={config.img + "ghost1.webp"}/>
                    <div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameBorder="0" width="100%" height="100%" allowFullScreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>
                    <button className="main-nav-link" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                  </div>
                </Link>,
                <Link style={{textAlign: "center"}} to="/game-preview/birthday-bonanza" >
                  <div style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <br/>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>BIRTHDAY BONANZA</h2>
                    <ImgNextGen width="80px" height="80px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.webp"}/><ImgNextGen width="80px" height="80px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-villianSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-villianSprite.webp"}/><ImgNextGen width="50px" height="50px" fallback={config.img + "ghost1.png"} srcWebp={config.img + "ghost1.webp"}/>
                    <div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameBorder="0" width="100%" height="100%" allowFullScreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>
                    <button className="main-nav-link" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                  </div>
                </Link>,
              ]}
            />
            <h1 className="filled-text-red" style={{textAlign: "center", fontSize: "2.5rem"}}>MOST POPULAR GAMES</h1>
            <ContentGrid
              desktopColumns="23% 22% 23% 22%"
              mobileColumns="100%"
              columnSpacing="3%"
              rowSpacing="30px"
              elements={[
                <Link style={{textAlign: "center"}} to="/game-preview/to-birthday-and-beyond" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>TO BIRTHDAY AND BEYOND</h2>
                    <ImgNextGen width="60px" height="60px" fallback={config.img + "2158ba90-2bff-11eb-8b95-f52af653a0f0-heroStandSprite.png"} srcWebp={config.img + "2158ba90-2bff-11eb-8b95-f52af653a0f0-heroStandSprite.webp"}/><ImgNextGen width="60px" height="60px" fallback={config.img + "space-villian.png"} srcWebp={config.img + "space-villian.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "space-vial.png"} srcWebp={config.img + "space-vial.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "to-birthday-and-beyond.png"} srcWebp={config.img + "to-birthday-and-beyond.webp"} />
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>,
                <Link style={{textAlign: "center"}} to="/game-preview/workshop-dash" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>WORKSHOP DASH</h2>
                    <ImgNextGen width="50px" height="50px" fallback={config.img + "89c48b30-2c05-11eb-945b-c9c4d0a14662-heroStandSprite.png"} srcWebp={config.img + "89c48b30-2c05-11eb-945b-c9c4d0a14662-heroStandSprite.webp"}/><ImgNextGen width="40px" height="40px" marginRight="15px" fallback={config.img + "toy-mouse.png"} srcWebp={config.img + "toy-mouse.webp"}/><ImgNextGen width="35px" height="35px" marginRight="15px" fallback={config.img + "milk-cookies.png"} srcWebp={config.img + "milk-cookies.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "christmas-train.png"} srcWebp={config.img + "christmas-train.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "workshop-dash.png"}  srcWebp={config.img + "workshop-dash.webp"}/>
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>,
                <Link style={{textAlign: "center"}} to="/game-preview/adventures-of-you" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>ADVENTURES OF [YOU]</h2>
                    <ImgNextGen width="60px" height="60px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.webp"}/><ImgNextGen width="60px" height="60px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-villianSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-villianSprite.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "theif.png"} srcWebp={config.img + "theif.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "adventures-of-you.png"} srcWebp={config.img + "adventures-of-you.webp"} />
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>,
                <Link style={{textAlign: "center"}} to="/game-preview/beat-the-ball-drop" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>BEAT THE BALL DROP</h2>
                    <ImgNextGen width="50px" height="50px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.webp"}/><ImgNextGen width="40px" height="40px" marginRight="15px" fallback={config.img + "new-years-ball.png"} srcWebp={config.img + "new-years-ball.webp"}/><ImgNextGen width="35px" height="35px" marginRight="15px" fallback={config.img + "office-worker.png"} srcWebp={config.img + "office-worker.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "new-years-hat.png"} srcWebp={config.img + "new-years-hat.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "beat-the-ball-drop.png"} srcWebp={config.img + "beat-the-ball-drop.webp"}/>
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>,
              ]}
            />
            <ContentGrid
              desktopColumns="31% 31% 31%"
              mobileColumns="100%"
              columnSpacing="3%"
              rowSpacing="30px"
              elements={[
                <Link style={{textAlign: "center"}} to="/game-preview/cupids-gauntlet" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>CUPID'S GAUNTLET</h2>
                    <ImgNextGen width="50px" height="50px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.webp"}/><ImgNextGen width="40px" height="40px" marginRight="15px" fallback={config.img + "cupid.png"} srcWebp={config.img + "cupid.webp"}/><ImgNextGen width="35px" height="35px" marginRight="15px" fallback={config.img + "pink-cloud.png"} srcWebp={config.img + "pink-cloud.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "triple-heart.png"} srcWebp={config.img + "triple-heart.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "cupids-gauntlet.png"} srcWebp={config.img + "cupids-gauntlet.webp"}/>
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>,
                <Link style={{textAlign: "center"}} to="/game-preview/birthday-derby" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>BIRTHDAY DERBY</h2>
                    <ImgNextGen width="50px" height="50px" fallback={config.img + "9bc9b590-2c22-11eb-945b-c9c4d0a14662-heroStandSprite.png"} srcWebp={config.img + "9bc9b590-2c22-11eb-945b-c9c4d0a14662-heroStandSprite.webp"}/><ImgNextGen width="40px" height="40px" marginRight="15px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-villianSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-villianSprite.webp"}/><ImgNextGen width="35px" height="35px" marginRight="15px" fallback={config.img + "racing-bonus-box.png"} srcWebp={config.img + "racing-bonus-box.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "tire.png"} srcWebp={config.img + "tire.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "birthday-derby.png"} srcWebp={config.img + "birthday-derby.webp"}/>
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>,
                <Link style={{textAlign: "center"}} to="/game-preview/christmas-slider" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>CHRISTMAS SLIDER</h2>
                    <ImgNextGen width="60px" height="60px" fallback={config.img + "68801520-2c37-11eb-945b-c9c4d0a14662-heroStandSprite.png"} srcWebp={config.img + "68801520-2c37-11eb-945b-c9c4d0a14662-heroStandSprite.webp"}/><ImgNextGen width="60px" height="60px" fallback={config.img + "acorn.png"} srcWebp={config.img + "acorn.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "wreath.png"} srcWebp={config.img + "wreath.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "christmas-slider.png"} srcWebp={config.img + "christmas-slider.webp"}/>
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>,
                <Link style={{textAlign: "center"}} to="/game-preview/beach-birthday" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>BEACH BIRTHDAY</h2>
                    <ImgNextGen width="50px" height="50px" fallback={config.img + "245f81f0-2caf-11eb-945b-c9c4d0a14662-heroStandSprite.png"} srcWebp={config.img + "245f81f0-2caf-11eb-945b-c9c4d0a14662-heroStandSprite.webp"}/><ImgNextGen width="50px" height="50px" fallback={config.img + "5fa6adb0-2caf-11eb-945b-c9c4d0a14662-heroStandSprite.png"} srcWebp={config.img + "5fa6adb0-2caf-11eb-945b-c9c4d0a14662-heroStandSprite.webp"}/><ImgNextGen width="35px" height="35px" marginRight="15px" fallback={config.img + "tropical-drink.png"} srcWebp={config.img + "tropical-drink.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "crab.png"}  srcWebp={config.img + "crab.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "beach-birthday.png"} srcWebp={config.img + "beach-birthday.webp"}/>
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>,
                <Link style={{textAlign: "center"}} to="/game-preview/valentines-date-dash" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>VALENTINE'S DATE DASH</h2>
                    <ImgNextGen width="60px" height="60px" fallback={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.png"} srcWebp={config.img + "4cdbcc90-2b85-11eb-a30b-ef281ec089e7-heroStandSprite.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "garlic.png"} srcWebp={config.img + "garlic.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "coffee.png"} srcWebp={config.img + "coffee.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "valentines-date-dash.png"} srcWebp={config.img + "valentines-date-dash.webp"}/>
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>,
                <Link style={{textAlign: "center"}} to="/game-preview/princess-yous-perils" >
                  <div className="feature-container" style={{boxShadow: "0 5px 8px 4px #888888", borderRadius: "7px"}}>
                    <h2 className="filled-text-blue" style={{marginBottom: "10px", fontSize: "2rem", textDecoration: "none"}}>PRINCESS [YOU]'S PERILS</h2>
                    <ImgNextGen width="60px" height="60px" fallback={config.img + "0668f290-2d8a-11eb-adf3-995b3ec6bb75-heroStandSprite.png"} srcWebp={config.img + "0668f290-2d8a-11eb-adf3-995b3ec6bb75-heroStandSprite.webp"}/><ImgNextGen width="60px" height="60px" fallback={config.img + "knight.png"} srcWebp={config.img + "knight.webp"}/><ImgNextGen width="35px" height="35px" fallback={config.img + "witch.png"} srcWebp={config.img + "witch.webp"}/>
                    <ImgNextGen width="95%" margin="auto" fallback={config.img + "princess-perils.png"} srcWebp={config.img + "princess-perils.webp"}/>
                    {/*<div style={{width: "95%", height: "0px", position: "relative", paddingBottom: "56.338%", margin: "auto"}}>
                      <iframe src="https://streamable.com/e/hputvb?autoplay=1" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style={{width:"100%",height:"100%",position:"absolute",left:"0px",top:"0px",overflow:"hidden"}}></iframe>
                    </div>*/}
                    <div className="feature-overlay">
                      <button className="main-nav-link feature-button" style={{marginBottom: "0.5rem"}}>Make This Game!</button>
                    </div>
                  </div>
                </Link>
              ]}
            />
            <br />
            {console.log(config, "Mus Config")}
          </PaddedSection>
        </Layout>
      )
    }
  }
export default IndexPage
